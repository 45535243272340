<template>
  <v-dialog
    eager
    v-model="dialog"
    max-width="900"
  >
    <template v-slot:activator="{ on, attrs }">
      <button
        v-bind="attrs"
        v-on="on"
        class="main-btn feedback-form__btn"
        :class="{'padding': padding}"
      >
        <slot></slot>
      </button>
    </template>
    <v-card>
      <iframe :src="iframeUrl" width="100%" height="750px" >{{
          t("loading")
        }}…</iframe>
    </v-card>
  </v-dialog>
</template>

<script>
// const PersonalData = () => import('./personalTerms/PersonalData')
export default {
  name: 'FeedbackForm',
  props: {
    padding: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  // components: { PersonalData },
  data () {
    return {
      // iframeUrl: 'https://docs.google.com/forms/d/e/1FAIpQLSewWSwDMSFAUMBY5KhpJqIzlSYjUPCeL7yzF93mnCHaJXsoyA/viewform?embedded=true',
      valid: false,
      dialog: false,
      companyName: '',
      employees: '',
      phone: '',
      mail: '',
      contactChannel: '',
      legal: false
    }
  },
  computed: {
    // eslint-disable-next-line vue/return-in-computed-property
    iframeUrl () {
      switch (this.$store.getters['app/getSelectedLocale']) {
        case 'uk':
          return 'https://docs.google.com/forms/d/e/1FAIpQLSdaf6baPLiGXN3VqeJvHWZ_qRPb5le_2jd6nxb56OmzytraVQ/viewform'
        case 'ru':
          return 'https://docs.google.com/forms/d/e/1FAIpQLSdaf6baPLiGXN3VqeJvHWZ_qRPb5le_2jd6nxb56OmzytraVQ/viewform'
      }
    }
  },
  methods: {
    t: function (key) {
      return this.$vuetify.lang.t('$vuetify.view.business.preview.' + key)
    }
  }
}
</script>

<style lang="scss" scoped>
.feedback__title  {
  font-weight: normal !important;
  font-size: 1.5rem;
  color: $darkTextColor;
  text-align: center;
  max-width: 80%;
  margin: auto;
}
.feedback-form__btn {
  color: #ffffff !important;
  margin: 0 auto;
}
.textField > .v-input__control > .v-input__slot {
  outline: 1px solid $primary;
  background-color: rgb(214, 226, 234) !important;
}
.padding {
  padding-left: 50px !important;
  padding-right: 50px !important;
}
</style>
