<template>
  <div>
    <preview-section></preview-section>
    <solve-section></solve-section>
    <find-section></find-section>
    <why-section></why-section>
    <selection-section></selection-section>
    <comfortable-section></comfortable-section>
    <faq-section></faq-section>
    <request-section></request-section>
  </div>
</template>

<script>
import PreviewSection from '@/components/main/Business/PreviewSection'
import SolveSection from '@/components/main/Business/SolveSection'
import FindSection from '@/components/main/Business/FindSection'
import WhySection from '@/components/main/Business/WhySection'
import SelectionSection from '@/components/main/Business/SelectionSection'
import ComfortableSection from '@/components/main/Business/ComfortableSection'
import FaqSection from '@/components/main/Business/FaqSection'
import RequestSection from '@/components/main/Business/RequestSection'
export default {
  name: 'Business',
  components: {
    PreviewSection,
    SolveSection,
    FindSection,
    WhySection,
    SelectionSection,
    ComfortableSection,
    FaqSection,
    RequestSection
  },
  metaInfo () {
    return {
      title: 'Корпоративний психолог онлайн┃Психологічна підтримка \n' +
        'співробітників',
      meta: [
        {
          vmid: 'og:description',
          property: 'og:description',
          name: 'description',
          content: 'Платформа – онлайн спільнота професійних психологів. \n' +
            'Підберемо найкращих для вашої команди ⋄ Створимо \n' +
            'сприятливий психологічний клімат в вашому колективі.'
        },
        {
          name: 'keywords',
          content: 'Психолог для співробітників, психотерапевт для \n' +
            'співробітників, корпоративний психолог, атмосфера в \n' +
            'колективі, ефективність роботи співробітників, підвищення \n' +
            'ефективності співробітників, управління ефективністю \n' +
            'співробітників, психологічний стан співробітників'
        }
      ]
    }
  }
}
</script>
<style>
.v-expansion-panel--active > .v-expansion-panel-header--active .v-expansion-panel-header__icon:not(.v-expansion-panel-header__icon--disable-rotate) .v-icon {
  transform: rotate(-135deg)!important;
}
.theme--light.v-expansion-panels .v-expansion-panel-header .v-expansion-panel-header__icon .v-icon{
  color: #00A4B7 !important;
}
</style>
