<template>
  <section class="preview">
    <div class="container">
      <div class="preview__content">
        <div class="preview__description">
          <h1 class="preview__title">{{
              $vuetify.lang.t("$vuetify.view.business.preview.mainTitle")
            }}</h1>
          <span class="preview__subtitle">{{
              $vuetify.lang.t("$vuetify.view.business.preview.subtitle")
            }}</span>
          <div class="preview__block">
            <div class="preview__link" >
              <feedback-form>{{
                  $vuetify.lang.t("$vuetify.view.business.preview.previewMainBtn")
                }}</feedback-form>
            </div>
            <h3 class="preview__decor-text decor-text">{{
                $vuetify.lang.t("$vuetify.view.business.preview.decorTextPart")
              }}</h3>
            <div class="preview__arrow">
              <img src="@/assets/images/business/arrow1.png" alt="arrow">
            </div>
          </div>
        </div>
        <div class="preview__picture">
          <img class="preview__main-img" src="@/assets/images/business/1.png" alt="talks">
        </div>
      </div>
    </div>
    <article class="preview__footer">
      <div class="container">
        <h2 class="section-title-sm">{{
            $vuetify.lang.t("$vuetify.view.business.preview.dignityTitle")
          }}</h2>
        <ul class="preview__dignity-list">
          <li class="preview__dignity-item"
              :class="{'preview__dignity-item--width' : i === 0}"
              v-for="(item, i) in previewList"
              :key="i">
            <h4 class="preview__dignity-title">{{ item.percentage }}</h4>
            <span class="preview__addition">{{ item.dignity }}</span>
          </li>
        </ul>
      </div>
    </article>
  </section>
</template>

<script>
import FeedbackForm from '@/components/UI/FeedbackForm'
export default {
  name: 'PreviewSection',
  components: {
    FeedbackForm
  },
  computed: {
    previewList () {
      return [
        {
          percentage: '+57%',
          dignity: this.$vuetify.lang.t('$vuetify.view.business.preview.dignity1')
        },
        {
          percentage: '+43%',
          dignity: this.$vuetify.lang.t('$vuetify.view.business.preview.dignity2')
        },
        {
          percentage: '+36%',
          dignity: this.$vuetify.lang.t('$vuetify.view.business.preview.dignity3')
        },
        {
          percentage: '+28%',
          dignity: this.$vuetify.lang.t('$vuetify.view.business.preview.dignity4')
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.v-application p {
  margin-bottom: 0;
}
.preview {
  padding: 60px 0 15px;

  &__content {
    display: flex;
    justify-content: space-between;
    gap: 90px;
  }

  &__description {
    text-align: center;
    max-width: 440px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 50px;
  }

  &__title {
    max-width: 420px;
    font-size: 2rem;
    line-height: normal;
    font-weight: 500;
    color: $darkGrey;
  }

  &__secondary-img {
    position: relative;
    top: 1.8rem;
    left: 30px;
    max-width: 290px;
  }

  &__subtitle {
    margin-top: 50px;
    max-width: 370px;
    font-weight: normal;
    font-size: 1.5rem;
    color: $darkTextColor;
  }

  &__main-btn {
    margin: 0 auto;
  }

  &__picture {
    display: flex;
    align-items: center;
    max-width: 550px;
  }

  &__block {
    display: grid;
    margin-top: 100px;
    margin-right: -90px;
    grid-template-columns: 320px auto;
    grid-auto-rows: 1fr;
  }

  &__link {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 2;
  }

  &__decor-text {
    font-size: 1.4rem;
    margin-top: 10px;
    line-height: 22px;
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 2;
    grid-row-end: 3;
  }

  &__arrow {
    display: flex;
    margin-left: 10px;
    align-items: center;
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 3;
  }

  &__arrow img {
    height: 80px;
  }

  &__main-img {
    width: 100%;
    height: auto;
    max-width: 600px;
    max-height: 600px;
  }

  &__footer {
    border-top: 2px solid $lightColor;
    margin-top: 50px;
  }

  &__dignity-list {
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
    list-style: none;
    text-align: center;
    color: $darkTextColor;
    padding-left: 0;
  }

  &__dignity-item {
    flex-basis: 235px;
  }

  &__dignity-item--width {
    flex-basis: 175px;
  }

  &__dignity-title {
    color: $darkTextColor;
    font-size: 1.4rem;
    font-weight: normal;
  }

  &__addition {
    color: $darkTextColor;
    font-size: 1rem;
  }
}
@media screen and (max-width: 768px) {
  .preview__content {
    flex-wrap: wrap;
    justify-content: center;
  }
  .preview__dignity-list {
    flex-wrap: wrap;
    gap: 20px;
  }
  .preview__dignity-item--width {
    flex-basis: 235px;
  }
}
</style>
