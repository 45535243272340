<template>
  <section class="find">
    <div class="container">
      <h2 class="section-title">{{
          $vuetify.lang.t("$vuetify.view.business.find.title")
        }}</h2>
      <h3 class="section-subtitle mt-2 d-flex justify-center">{{
          $vuetify.lang.t("$vuetify.view.business.find.subtitle")
        }}</h3>,
      <div class="find__content">
        <ul class="find__list">
          <li class="find__item" v-for="(find, i) in findList" :key="i">
            <div class="number-wrapper-2">
              <div class="number-2">{{ ++i }}</div>
            </div>
            <p class="find__description">{{ find.description }}</p>
          </li>
        </ul>
        <div class="find__image">
          <img class="find__img" src="@/assets/images/business/3.png" alt="talks">
        </div>
      </div>
      <router-link to="/">
        <button class="main-btn find__main-btn">{{
            $vuetify.lang.t("$vuetify.view.business.find.btn")
          }}</button>
      </router-link>
    </div>
  </section>
</template>

<script>
export default {
  name: 'FindSection',
  computed: {
    findList () {
      return [
        {
          description: this.$vuetify.lang.t('$vuetify.view.business.find.list.description1')
        },
        {
          description: this.$vuetify.lang.t('$vuetify.view.business.find.list.description2')
        },
        {
          description: this.$vuetify.lang.t('$vuetify.view.business.find.list.description3')
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.v-application p {
  margin-bottom: 0;
}
.find {
  padding: 50px 0;
  &__content {
    display: flex;
    gap: 30px;
    justify-content: space-evenly;
  }
  &__list {
    display: flex;
    flex-direction: column;
    gap: 40px;
    list-style: none;
    margin-top: 40px;
  }
  &__item {
    display: flex;
    align-items: center;
    gap: 30px;
  }
  &__description {
    color: $darkTextColor;
  }
  &__image {
    max-width: 400px;
    width: 100%;
  }
  &__img {
    width: 100%;
  }
  &__main-btn {
    margin: 50px auto 0;
  }
}
@media screen and (max-width: 768px) {
  .find__content {
    flex-wrap: wrap;
  }
}
</style>
