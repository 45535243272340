<template>
  <section class="request">
    <h2 class="request__title section-title">{{
        $vuetify.lang.t("$vuetify.view.business.request.title")
      }}</h2>
    <div class="request__link" >
      <feedback-form>{{
          $vuetify.lang.t("$vuetify.view.business.preview.previewMainBtn")
        }}</feedback-form>
    </div>
  </section>
</template>

<script>
import FeedbackForm from '@/components/UI/FeedbackForm'

export default {
  name: 'RequestSection',
  components: {
    FeedbackForm
  }
}
</script>

<style lang="scss" scoped>
.v-application p {
  margin-bottom: 0;
}
.request {
  padding: 60px;
  background-color: $backgroundColor;
  &__title {
    margin: 0 auto;
    max-width: 70%;
  }
  &__link {
    display: block;
    text-align: center;
    margin-top: 60px;
  }
  &__wrapper {
    background-color: white;
    height: 500px;
    width: 300px;
  }
}
</style>
