<template>

  <section class="comfortable">

    <div class="container">
      <h2 class="section-title">{{
          $vuetify.lang.t("$vuetify.view.business.comfortable.title")
        }}</h2>
      <ul class="comfortable__list">
        <li class="comfortable__item" v-for="(comfy, i) in comfortableList" :key="i">
          <div class="d-flex flex-column order-2">
            <h4 class="comfortable__item-title">{{ comfy.title }}</h4>
            <p class="comfortable__description">{{ comfy.description }}</p>
          </div>
          <div class="comfortable__image"
               :class="`comfortable__image--${++i}`">
            <img class="comfortable__img"
                 :class="`comfortable__img--${i}`"
                 :src="comfy.src"
                 alt="pic">
          </div>
        </li>
      </ul>
    </div>
  </section>
</template>

<script>
export default {
  name: 'ComfortableSection',
  computed: {
    comfortableList () {
      return [
        {
          title: this.$vuetify.lang.t('$vuetify.view.business.comfortable.list.title1'),
          description: this.$vuetify.lang.t('$vuetify.view.business.comfortable.list.description1'),
          src: require('@/assets/images/business/6_1.png')
        },
        {
          title: this.$vuetify.lang.t('$vuetify.view.business.comfortable.list.title2'),
          description: this.$vuetify.lang.t('$vuetify.view.business.comfortable.list.description2'),
          src: require('@/assets/images/business/6_2.png')
        },
        {
          title: this.$vuetify.lang.t('$vuetify.view.business.comfortable.list.title3'),
          description: this.$vuetify.lang.t('$vuetify.view.business.comfortable.list.description3'),
          src: require('@/assets/images/business/6_3.png')
        },
        {
          title: this.$vuetify.lang.t('$vuetify.view.business.comfortable.list.title4'),
          description: this.$vuetify.lang.t('$vuetify.view.business.comfortable.list.description4'),
          src: require('@/assets/images/business/6_4.png')
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.v-application p {
  margin-bottom: 0;
}
.comfortable {
  padding: 70px 0;
  background-color: $backgroundColor;
  &__list {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 50px;
    margin-top: 50px;
  }
  &__item {
    position: relative;
    display: flex;
    border-radius: 13px;
    border: 2px solid $lightColor;
    padding: 50px 20px;
    flex-basis: 550px;
    min-height: 200px;
    line-height: normal;
  }
  &__image {
    flex-basis: 120px;
    flex-shrink: 0;
    height: 70px;
    &--1 {
      order: 3;
    }
    &--2 {
      order: 1;
    }
    &--3 {
      order: 3;
    }
    &--4 {
      order: 1;
    }
  }
  &__img {
    position: absolute;
    height: 120px;
    &--1 {
      right: -1px;
      bottom: -1px;
    }
    &--2 {
      left: -1px;
      bottom: -1px;
    }
    &--3 {
      right: -1px;
      top: -1px;
    }
    &--4 {
      left: -1px;
      top: -1px;
    }
  }
  &__item-title {
    color: $lightColor;
    font-size: 1.3rem;
    font-weight: 400;
  }
  &__description {
    margin-top: 12px;
    font-size: 1rem;
    color: $darkTextColor
  }
}
</style>
