<template>
  <section class="selection">
    <div class="container">
      <h2 class="section-title">{{
          $vuetify.lang.t("$vuetify.view.business.selection.title")
        }}</h2>
      <h3 class="section-subtitle d-flex justify-center">{{
          $vuetify.lang.t("$vuetify.view.business.selection.subtitle")
        }}</h3>
      <ul class="selection__list">
        <li class="selection__item" v-for="(select, i) in selectionList" :key="i">
          <img class="selection__img" src="@/assets/images/business/5.png" alt="check">
          <h4 class="selection__item-title">{{ select.title }}</h4>
          <p class="selection__description">{{ select.description }}</p>
        </li>
      </ul>
      <div class="d-flex justify-center mt-9">
          <span class="decor-text text-center">{{
              $vuetify.lang.t("$vuetify.view.business.selection.decorText1")
            }}
          <br>{{
              $vuetify.lang.t("$vuetify.view.business.selection.decorText2")
            }}</span>
        <img class="selection__arrow" src="@/assets/images/business/arrow3.png" alt="arrow">
      </div>
      <div class="d-flex justify-center mt-10">
        <FeedbackForm padding>{{
            $vuetify.lang.t("$vuetify.view.business.selection.btn")
          }}</FeedbackForm>
      </div>
    </div>
  </section>
</template>

<script>
import FeedbackForm from '@/components/UI/FeedbackForm'

export default {
  name: 'SelectionSection',
  components: {
    FeedbackForm
  },
  computed: {
    selectionList () {
      return [
        {
          title: this.$vuetify.lang.t('$vuetify.view.business.selection.list.title1'),
          description: this.$vuetify.lang.t('$vuetify.view.business.selection.list.description1')
        },
        {
          title: this.$vuetify.lang.t('$vuetify.view.business.selection.list.title2'),
          description: this.$vuetify.lang.t('$vuetify.view.business.selection.list.description2')
        },
        {
          title: this.$vuetify.lang.t('$vuetify.view.business.selection.list.title3'),
          description: this.$vuetify.lang.t('$vuetify.view.business.selection.list.description3')
        },
        {
          title: this.$vuetify.lang.t('$vuetify.view.business.selection.list.title4'),
          description: this.$vuetify.lang.t('$vuetify.view.business.selection.list.description4')
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.v-application p {
  margin-bottom: 0;
}
.selection {
  padding: 50px 0;
  &__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 50px;
    list-style: none;
    margin-top: 40px;
  }
  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 40%;
    min-width: 300px;
    line-height: normal;
  }
  &__img {
    max-width: 40px;
  }
  &__item-title {
    color: $lightColor;
    margin-top: 10px;
    font-weight: normal;
    font-size: 1.3rem;
  }
  &__description {
    color: $darkTextColor;
    text-align: center;
    margin-top: 5px;
    font-size: 1rem;
  }
  &__arrow {
    width: 60px;
    margin-right: -60px;
    position: relative;
    top: 20px;
  }
}
</style>
