<template>
  <section class="why">
    <div class="container">
      <h2 class="section-title">{{
          $vuetify.lang.t("$vuetify.view.business.why.title")
        }}</h2>
      <ul class="why__list">
        <li class="why__item" v-for="(why, i) in whyList" :key="i">
          <div class="why__image">
            <img :src="why.src" alt="pic" class="why__img">
          </div>
          <article class="why__text">
            <h3 class="why__subtitle">{{ why.title }}</h3>
            <p class="why__description">{{ why.description }}</p>
          </article>
        </li>
      </ul>
    </div>
  </section>
</template>

<script>
export default {
  name: 'WhySection',
  computed: {
    whyList () {
      return [
        {
          title: this.$vuetify.lang.t('$vuetify.view.business.why.list.title1'),
          description: this.$vuetify.lang.t('$vuetify.view.business.why.list.description1'),
          src: require('@/assets/images/business/4_1.png')
        },
        {
          title: this.$vuetify.lang.t('$vuetify.view.business.why.list.title2'),
          description: this.$vuetify.lang.t('$vuetify.view.business.why.list.description2'),
          src: require('@/assets/images/business/4_2.png')
        },
        {
          title: this.$vuetify.lang.t('$vuetify.view.business.why.list.title3'),
          description: this.$vuetify.lang.t('$vuetify.view.business.why.list.description3'),
          src: require('@/assets/images/business/4_3.png')
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.v-application p {
  margin-bottom: 0;
}
.why {
  padding: 60px 0;
  background-color: $backgroundColor;
  &__list {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 50px;
    list-style: none;
    margin-top: 70px;
  }
  &__item {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    width: 65%;
    padding: 15px 60px;
    background-color: white;
    border: 2px solid $lightColor;
    border-radius: 8px;
  }
  &__image {
    max-width: 100px;
    width: 100%;
  }
  &__img {
    width: 100%;
  }
  &__text {
    display: flex;
    justify-content: center;
    gap: 10px;
    flex-direction: column;
    width: 70%;
    line-height: normal;
  }
  &__subtitle {
    color: $lightColor;
    font-weight: 400;
    font-size: 1.3rem;
  }
  &__description {
    font-size: 1rem;
    color: $darkTextColor;
  }
}
</style>
