<template>
  <section class="faq">
    <div class="container">
      <h2 class="section-title">{{
          $vuetify.lang.t("$vuetify.view.business.faq.FAQ")
        }}</h2>
      <div class="mt-16">
        <v-expansion-panels
          v-model="panel"
          multiple
        >
          <v-expansion-panel
            v-for="faq in faqList"
            :key="faq.id"
            @click="openModal(faq)"
          >
            <v-expansion-panel-header class="faq__item-title list-title" expand-icon="mdi-plus"> {{ faq.title }}</v-expansion-panel-header>
            <v-expansion-panel-content class="faq__item-description" v-for="(item, i) in faq.description" :key="i">{{ item }}</v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>
      <h3 class="section-subtitle mt-16 d-flex justify-center">{{
          $vuetify.lang.t("$vuetify.view.business.faq.subtitle")
        }}</h3>
      <div class="faq__social">
        <div class="mt-15 d-flex align-center">
          <a href="https://wa.me/380673077887" target="_blank">
            <img class="faq__social-item" src="@/assets/images/business/watsupp.png" alt="watsupp">
          </a>
          <a class="faq__phone ml-6" href="tel:+380673077887">
            <span class="faq__phone-text">067-307-78-87</span>
          </a>
        </div>
        <a class="faq__phone mt-4 d-flex align-center" href="mailto: business@platforma.life" target="_blank">
          <img class="faq__social-item" src="@/assets/images/business/mail.png" alt="mailto">
          <span class="faq__phone-text ml-6">business@platforma.life</span>
        </a>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'FaqSection',
  computed: {
    faqList () {
      return [
        {
          id: 1,
          title: this.$vuetify.lang.t('$vuetify.view.business.faq.question1'),
          description: {
            item: this.$vuetify.lang.t('$vuetify.view.business.faq.answer1')
          },
          isOpen: false
        },
        {
          id: 2,
          title: this.$vuetify.lang.t('$vuetify.view.business.faq.question2'),
          description: {
            item: this.$vuetify.lang.t('$vuetify.view.business.faq.answer2')
          },
          isOpen: false
        },
        {
          id: 3,
          title: this.$vuetify.lang.t('$vuetify.view.business.faq.question3'),
          description: {
            item: this.$vuetify.lang.t('$vuetify.view.business.faq.answer3')
          },
          isOpen: false
        },
        {
          id: 4,
          title: this.$vuetify.lang.t('$vuetify.view.business.faq.question4'),
          description: {
            item: this.$vuetify.lang.t('$vuetify.view.business.faq.answer4')
          },
          isOpen: false
        },
        {
          id: 5,
          title: this.$vuetify.lang.t('$vuetify.view.business.faq.question5'),
          description: {
            item: this.$vuetify.lang.t('$vuetify.view.business.faq.answer5')
          },
          isOpen: false
        },
        {
          id: 6,
          title: this.$vuetify.lang.t('$vuetify.view.business.faq.question6'),
          description: {
            item: this.$vuetify.lang.t('$vuetify.view.business.faq.answer6')
          },
          isOpen: false
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.v-application p {
  margin-bottom: 0;
}
.faq {
  padding: 60px 0;
  &__item-title {
    font-size: 1.3rem !important;
    font-weight: 300 !important;
    z-index: 10;
    color: $lightColor;
  };
  &__item-description {
    color: $darkTextColor;
    font-weight: 300 !important;
  };
  &__btn {
    display: block;
    margin: 100px auto;
    max-width: 320px;
    width: 100%;
    font-size: 1.9rem;
    text-decoration: none;
    text-align: center;
    color: white;
    font-weight: 300;
  }
  &__item-icon {
    position: absolute;
    margin-top: -10px;
    margin-right: 20px;
    top: 0;
    right: 0;
    font-size: 50px;
    font-weight: 300;
    transition: 0.5s;
    color: $lightColor;
  }
  &__social {
    max-width: 300px;
    margin: 0 auto;
  }
  &__social-item {
    max-width: 35px;
  }
  &__phone {
    text-decoration: none;
  }
  &__phone-text {
    color: $darkTextColor;
    font-size: 1.2rem;
  }
}
</style>
