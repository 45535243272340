<template>
  <section class="solve">
    <div class="container">
      <h2 class="section-title">{{
          $vuetify.lang.t("$vuetify.view.business.solve.mainTitle")
        }}</h2>
      <ul class="solve__list">
        <li class="solve__item" v-for="(solve, i) in solveList" :key="i">
          <div class="number-wrapper">
            <div class="number">{{ ++i }}</div>
          </div>
          <div class="solve__devider"></div>
          <p class="solve__description">{{ solve.description }}</p>
        </li>
      </ul>
      <h2 class="section-title solve__title--second">{{
          $vuetify.lang.t("$vuetify.view.business.solve.secondTitle")
        }}</h2>
      <div class="solve__block">
        <feedback-form>{{
            $vuetify.lang.t("$vuetify.view.business.preview.previewMainBtn")
          }}</feedback-form>
        <h3 class="solve__decor-text decor-text">{{
            $vuetify.lang.t("$vuetify.view.business.solve.decorText")
          }}</h3>
        <div class="solve__arrow">
          <img src="@/assets/images/business/arrow2.png" alt="arrow">
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import FeedbackForm from '@/components/UI/FeedbackForm'
export default {
  name: 'SolveSection',
  components: {
    FeedbackForm
  },
  computed: {
    solveList () {
      return [
        {
          description: this.$vuetify.lang.t('$vuetify.view.business.solve.list.description1')
        },
        {
          description: this.$vuetify.lang.t('$vuetify.view.business.solve.list.description2')
        },
        {
          description: this.$vuetify.lang.t('$vuetify.view.business.solve.list.description3')
        },
        {
          description: this.$vuetify.lang.t('$vuetify.view.business.solve.list.description4')
        },
        {
          description: this.$vuetify.lang.t('$vuetify.view.business.solve.list.description5')
        },
        {
          description: this.$vuetify.lang.t('$vuetify.view.business.solve.list.description6')
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.v-application p {
  margin-bottom: 0;
}
.solve {
  padding: 70px 0 50px;
  background-color: $backgroundColor;
  &__title--second {
    max-width: 920px;
    margin: 70px auto 0;
    color: $darkTextColor;
  }
  &__list {
    margin-top: 70px;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    gap: 25px;
    list-style: none;
  }
  &__item {
    display: flex;
    align-items: center;
    flex-basis: 520px;
    background-color: white;
    min-height: 80px;
    padding: 0 20px;
    border: 2px solid $lightColor;
    border-radius: 8px;
  }
  &__devider {
    flex-basis: 2px;
    flex-shrink: 0;
    min-height: 50px;
    background-color: $lightColor;
    margin: 0 35px;
  }
  &__description {
    color: $darkTextColor;
    text-align: center;
    line-height: normal;
  }
  &__main-btn {
    display: block;
    margin: 0 auto;
  }
  &__block {
    margin-top: 100px;
    display: grid;
    justify-content: center;
    margin-right: -90px;
    grid-template-columns: 320px auto;
    grid-auto-rows: 1fr;
  }
  &__link {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 2;
  }
  &__decor-text {
    font-size: 1.4rem;
    margin-top: 15px;
    line-height: 22px;
    text-align: center;
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 2;
    grid-row-end: 3;
  }
  &__arrow {
    display: flex;
    margin-left: 10px;
    align-items: center;
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 3;
  }
  &__arrow img {
    position: relative;
    right: 25px;
    bottom: 10px;
    height: 80px;
  }
}
</style>
